<template>
    <div class="p-grid">
        <Toast />
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>검색</strong>
                </h4>
                <div class="p-formgrid p-grid p-mb-3">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="Reported">피신고자 닉네임</label>
                        <InputText id="Chatroomname" v-model="reported_user" type="text" placeholder="피신고자 닉네임으로 검색" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="Chatroomname">신고 횟수 최소값</label>
                        <InputText id="Chatroomname" v-model="repoted_count"  onkeypress="return /[0-9]/i.test(event.key)" type="text" placeholder="최소값을 입력하세요" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="pass">마지막으로 신고된 날 기간 설정</label>
                        <Calendar inputId="range" v-model="date_range" selectionMode="range" :manualInput="false" placeholder="YYYY/MM/DD - YYYY/MM/DD" />
                    </div>
                </div>

                <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                    <div class="p-mb-4 p-mb-lg-0">
                        <!-- <Button :label="$t('button.today')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="today"></Button>
                        <Button :label="$t('button.lastWeek')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastweek"></Button>
                        <Button :label="$t('button.lastMonth')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastmonth"></Button>
                        <Button :label="$t('button.last6Months')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastsixmonth"></Button> -->
                        <!-- <Button :label="$t('button.lastYear')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastyear"></Button> -->
                    </div>
                    <div>
                        <Button label="검색" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchreportedUsers"></Button>
                        <Button label="초기화" icon="pi pi-replay" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="resetchreportedUsers"></Button>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <div>
                        <h5>피신고자 목록</h5>
                    </div>
                    <div>
                        <!-- <Button label="Primary" class="p-mr-2 p-mb-2" @click="excelUpload"><i class="pi pi-download p-mr-2"></i>{{ $t('Upload Excel') }}</Button> -->
                        <!-- <Button target="_blank" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="popularlistDownloadExcel" style="background: blue; color: white">엑셀 다운로드</Button> -->
                    </div>
                </div>

                <DataTable :value="customer1" :paginator="false" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" :loading="loading" :filters="filters" responsiveLayout="scroll">
                    <!-- v-model:selection="selected" -->
                    <ConfirmDialog group="dialog" />

                    <template #empty> 데이터가 없습니다. </template>
                    <template #loading> 데이터를 로드 중입니다. 기다리다. </template>

                    <!-- <column selectionMode="multiple" style="width: 16px; text-align: center" /> -->
                    <Column field="slNO" :header="$t('#')" :sortable="true">
                        <template #body="{ index }">
                            <span class="p-column-title">slNo</span>

                            {{ pagination.current_page * pagination.pagination_limit - pagination.pagination_limit + (index + 1) }}
                        </template>
                    </Column>
                    <Column header="피신고자 닉네임" style="min-width: 12rem" field="report_user">
                        <template #body="{ data }">
                            <span class="p-column-title">Reported User Nickname</span>
                            {{ data.report_user }}
                        </template>
                    </Column>

                    <Column header="신고당한 횟수" style="min-width: 16rem" field="report_counts"  :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">No. of times reported</span>
                            {{ data.report_counts }}
                        </template>
                    </Column>

                    <Column header="신고당한 날 중 가장 최신일자" style="min-width: 15rem" field="reportDate" :sortable="true">
                        <template #body="{ data }">
                            <span class="p-column-title">Last Reported Date & Time</span>
                            <span v-if="data.lastreportDate == '' || data.lastreportDate == null">-</span>
                            <span v-else>
                                {{ formatDate(data.lastreportDate) }}
                            </span>
                        </template>
                    </Column>

                    <Column header="관리" style="min-width: 6rem">
                        <template #body="{ data }">
                            <span class="p-column-title">Action</span>
                            <p style="display: none">{{ data.mobile }}</p>
                            <div style="display: flex">
                                <router-link :to="'/Reportedusersbycount/ReportedusersbycountDetail/' + data.report_userId "
                                    ><Button label="help" class="p-button2"><i class="pi pi-eye p-mr-2"></i> </Button
                                ></router-link>
                            </div>
                        </template>
                    </Column>
                </DataTable>

                <div class="p-paginator p-component p-paginator-bottom">
                    <button @click="paginate(1)" :class="{ 'p-disabled': pagination.current_page === 1 }" class="p-paginator-first p-paginator-element p-link" type="button" :disabled="pagination.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-double-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(pagination.current_page - 1)" :class="{ 'p-disabled': pagination.current_page === 1 }" class="p-paginator-next p-paginator-element p-link" type="button" :disabled="pagination.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <span class="p-paginator-pages">
                        <button
                            v-for="(page, index) in get_paginator_page(pagination.total_pages, pagination.current_page)"
                            :key="index"
                            @click="paginate(page)"
                            class="p-paginator-page p-paginator-element p-link"
                            :class="{ 'p-highlight': page === pagination.current_page }"
                            type="button"
                        >
                            {{ page }}<span class="p-ink"></span>
                        </button>
                    </span>
                    <button
                        @click="paginate(pagination.current_page + 1)"
                        :class="{ 'p-disabled': pagination.current_page === pagination.total_pages }"
                        class="p-paginator-next p-paginator-element p-link"
                        type="button"
                        :disabled="pagination.current_page === pagination.total_pages"
                    >
                        <span class="p-paginator-icon pi pi-angle-right"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button
                        @click="paginate(pagination.total_pages)"
                        :class="{ 'p-disabled': pagination.current_page === pagination.total_pages }"
                        :disabled="pagination.current_page === pagination.total_pages"
                        class="p-paginator-last p-paginator-element p-link"
                        type="button"
                    >
                        <span class="p-paginator-icon pi pi-angle-double-right"></span>
                        <span class="p-ink"></span>
                    </button>
                </div>

                <div style="text-align: right; margin-top: -35px">
                    <label for="order">
                        {{ pagination.total_records }}개 중 {{ pagination.current_page * pagination.pagination_limit - pagination.pagination_limit + 1 }} -
                        {{ pagination.total_pages === pagination.current_page ? pagination.total_records : pagination.current_page * pagination.pagination_limit - pagination.pagination_limit + 10 }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import { useRoute } from 'vue-router';
// import validateUsersearch from '../../validations/user/validateUserSearch';
// import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ReportedUsersbycountsService from '../../service/API/ReportedUsersbycountsService';
// import axios from 'axios';
import moment from 'moment';
import utils from '@/service/utils';
export default {
    data() {
        return {
            selected: [],
            render: true,
            display: false,
            position: 'center',
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
            totalrecords: '',
            pagination: {
                current_page: 1,
                pagination_limit: 10,
                total_pages: 0,
                total_records: 0,
            },
            // complexvalue: [],
            statusTrue: true,
            statusFalse: false,
            customer1: [],
            loading: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            room_name: '',
            owner: '',
            room_type: '',
            room_id: '',
            room_users_count: '',
            dropdownchatroomtype: [
                { name: '전체', code: 'all' },
                { name: '일반', code: 'general' },
                { name: '위치 기반', code: 'location' },
                { name: '패키지', code: 'package' },
            ],
            dropdownchatroomtypes: null,

            dropdownreportedreason: [
                { name: '유해성 메시지(도배/욕설/음란)', code: 'harmful' },
                { name: '불법 정보 유포', code: 'illegal' },
                { name: '광고/사기', code: 'scam' },
                { name: '기타', code: 'other' },
            ],

            dropdownreportedreasons: null,

            city: '',
            country: '',
            id: '',
            to_be_deleted: '',
            deleted: '',
            created_date: '',
            last_activity_date: '',
            status: '',
            room_passcode: '',
            report_reason: '',
            report_user: '',
            report_by: '',
            search_key: '',
            group_type: '',
            user: '',
            date_range: [],
            lastdate_range: '',
            create_start_date: '',
            create_end_date: '',
            last_start_date: '',
            last_end_date: '',
            is_secret: '',
            searchdate: '',
            searchenddate: '',
            searclasthdate: '',
            searchlastenddate: '',
            cities: '',
            reportDate: '',
            chatroom_name: '',
            reason_type: '',
            reported_user: '',
            repoted_count: '',
            report_userId:'',
        };
    },
    customerService: null,
    productService: null,
    created() {
        this.reportedUsersbycountsService = new ReportedUsersbycountsService();

        // this.reason_type = localStorage.getItem('reportuserreason_type');
        // this.chatroom_name = localStorage.getItem('reportuserchatroom_name');
        // this.group_type = localStorage.getItem('reportusergroup_type');
        // this.reported_user = localStorage.getItem('reportuserreported_user');
        // this.reported_by = localStorage.getItem('reportuserreported_by');
        // this.create_start_date = localStorage.getItem('reportusercreate_start_date');
        // this.create_end_date = localStorage.getItem('reportusercreate_end_date');
        // this.is_secret = localStorage.getItem('reportuseris_secret');
        // this.reportuserlistpagination = localStorage.getItem('reportuserlistpagination');

        // let sdate = localStorage.getItem('reportusercreate_start_date');
        // let edate = localStorage.getItem('reportusercreate_end_date');

        // if (sdate == '' && edate == '') {
        //     console.log(sdate, edate);
        // } else {
        //     this.searchdate = sdate;
        //     this.searchenddate = edate;

        //     this.date_range = [new Date(sdate), new Date(edate)];
        // }

        this.storageData = localStorage.getItem('ReportedUserscounts') ? JSON.parse(localStorage.getItem('ReportedUserscounts')) : '';

        if (this.storageData != '') {
            this.reported_user = this.storageData.reported_user == undefined ? '' : this.storageData.reported_user;
            this.repoted_count = this.storageData.repoted_count == undefined ? '' : this.storageData.repoted_count;
            this.reportusercountlistpagination = this.storageData.page;

            let dataRangeValOne = this.storageData.create_start_date ? new Date(this.storageData.create_start_date) : '';
            let dataRangeValTwo = this.storageData.create_end_date ? new Date(this.storageData.create_end_date) : '';
            this.date_range = [dataRangeValOne, dataRangeValTwo];
        }

        if (this.reportusercountlistpagination == '' || this.reportusercountlistpagination == null) {
            if (this.reported_user || this.repoted_count || this.create_start_date || this.create_end_date) {
                const searchLocalParams = {
                    record_count: this.pagination.pagination_limit,
                    page: this.pagination.current_page,
                    reported_user: this.reported_user == undefined ? '' : this.reported_user,
                    repoted_count: this.repoted_count == undefined ? '' : this.repoted_count,
                    create_start_date: this.create_start_date,
                    create_end_date: this.create_end_date,
                };

                this.reportedUserslist(searchLocalParams);
            } else {
                this.reportedUserslist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
            }
        } else {
            this.reportedUserslist({
                record_count: this.pagination.pagination_limit,
                page: this.reportusercountlistpagination,
                reported_user: this.reported_user == undefined ? '' : this.reported_user,
                repoted_count: this.repoted_count == undefined ? '' : this.repoted_count,
                create_start_date: this.create_start_date,
                create_end_date: this.create_end_date,
            });
        }
    },
    mounted() {

    },
    watch: {},
    methods: {
        getStatus(values) {
            return values === 'active' ? true : false;
        },

        reportedUserslist(data) {
            this.loading = true;
            this.reportedUsersbycountsService
                .getreportedUserscountList(data)
                .then((res) => {
                    this.customer1 = res.data.report_details;
                    this.totalrecords = res.data.total_records;
                    //                 this.company_id= this.company.id;
                    // console.log("this.company_id",this.company_id);
                    console.log('res', this.customer1);
                    console.log('total', this.totalrecords);
                    console.log(res);
                    this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading = false;
                })
                .catch((err) => console.log(err));
        },

        setPagination(current_page, total_pages, total_records) {
            this.pagination.current_page = current_page;
            this.pagination.total_pages = total_pages;
            this.pagination.total_records = total_records;
        },

        get_paginator_page(total, active) {
            return utils.paginate(total, active);
        },

        paginate(page) {
            this.loading = true;
            // var start_date = this.formatRangeDate(this.date_range.at(0));
            // var end_date = this.formatRangeDate(this.date_range.at(1));
            localStorage.setItem(
                'ReportedUserscounts',
                JSON.stringify({
                    page: page,
                    reported_user: this.reported_user,
                    repoted_count: this.repoted_count,
                    create_start_date: this.searchdate,
                    create_end_date: this.searchenddate,
                })
            );
            this.reportedUsersbycountsService
                .getreportedUserscountList({
                    record_count: this.pagination.pagination_limit,
                    page: page,
                    reported_user: this.reported_user,
                    repoted_count: this.repoted_count,
                    create_start_date: this.searchdate,
                    create_end_date: this.searchenddate,
                })
                .then((res) => {
                    this.customer1 = res.data.report_details;
                    this.totalrecords = res.data.total_records;
                    console.log('paginate', this.customer1);
                    console.log('paginatetotal', this.totalrecords);
                    this.pagination.current_page = page;
                    this.loading = false;
                    // console.log(res.data);
                })
                .catch((err) => console.log(err));
        },

        resetchreportedUsers() {
            localStorage.setItem('ReportedUserscounts', '');
            (this.reported_user = ''), (this.repoted_count = ''), (this.date_range = ''), this.reportedUserslist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
            window.location.reload();
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },
        searchreportedUsers() {
            if (this.reported_user !== '' || this.repoted_count !== '' || this.date_range !== '') {
                // if (this.date_range.at(0) != '') {
                //     this.searchdate = this.formatRangeDate(this.date_range.at(0));
                // } else {
                //     this.searchdate = '';
                // }

                // if (this.date_range.at(1) != '') {
                //     this.searchenddate = this.formatRangeDate(this.date_range.at(1));
                // } else {
                //     this.searchenddate = '';
                // }

                if (this.date_range.at(0) == '' || this.date_range.at(0) == null || this.date_range.at(0) == undefined) { this.searchdate = '';
                } else { this.searchdate = this.formatRangeDate(this.date_range.at(0)); }

                if (this.date_range.at(1) == '' || this.date_range.at(1) == null || this.date_range.at(1) == undefined) { this.searchenddate = '';
                } else { this.searchenddate = this.formatRangeDate(this.date_range.at(1)) }

                // localStorage.setItem('reportuserreason_type', this.reason_type);
                // localStorage.setItem('reportuserchatroom_name', this.chatroom_name);
                // localStorage.setItem('reportusergroup_type', this.group_type);
                // localStorage.setItem('reportuserreported_user', this.reported_user);
                // localStorage.setItem('reportuserreported_by', this.reported_by);
                // localStorage.setItem('reportusercreate_start_date', this.searchdate);
                // localStorage.setItem('reportusercreate_end_date', this.searchenddate);
                // localStorage.setItem('reportuseris_secret', this.is_secret);

                localStorage.setItem(
                    'ReportedUserscounts',
                    JSON.stringify({
                        reported_user: this.reported_user,
                        repoted_count: this.repoted_count,
                        create_start_date: this.searchdate,
                        create_end_date: this.searchenddate,
                        page: this.pagination.current_page,
                    })
                );

                let searchParams = {
                    record_count: this.pagination.pagination_limit,
                    page: 1,
                    reported_user: this.reported_user,
                    repoted_count: this.repoted_count,
                    create_start_date: this.searchdate,
                    create_end_date: this.searchenddate,

                    // startDate: start_date,
                    // endDate: end_date,
                };
                console.log('searchParams', searchParams);
                this.customer1 = true;
                this.reportedUsersbycountsService
                    .getreportedUserscountList(searchParams)
                    .then((res) => {
                        console.log('res', res);
                        this.customer1 = res.data.report_details;
                        console.log('searchPost', this.customer1);
                        this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                        this.loading1 = false;
                    })
                    .catch(() => {
                        this.customer1 = [];
                        this.loading1 = false;
                    });
            }
        },
        today() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate());
            this.date_range = [firstDate, today];
            this.searchreportedUsers();
        },
        lastweek() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            this.date_range = [firstDate, today];
            this.searchreportedUsers();
        },
        lastmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 31);
            this.date_range = [firstDate, today];
            this.searchreportedUsers();
        },
        lastsixmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 182);
            this.date_range = [firstDate, today];
            this.searchreportedUsers();
        },

        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },

        onRowExpand(event) {
            this.$toast.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
        },
        onRowCollapse(event) {
            this.$toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
        },
        expandAll() {
            this.expandedRows = this.products.filter((p) => p.id);
            this.$toast.add({ severity: 'success', summary: 'All Rows Expanded', life: 3000 });
        },
        collapseAll() {
            this.expandedRows = null;
            this.$toast.add({ severity: 'success', summary: 'All Rows Collapsed', life: 3000 });
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        calculateCustomerTotal(name) {
            let total = 0;
            if (this.customer3) {
                for (let customer of this.customer3) {
                    if (customer.representative.name === name) {
                        total++;
                    }
                }
            }

            return total;
        },
    },
};
</script>

<style lang="scss" scoped>
.p-datatable-tbody {
    .p-button {
        white-space: nowrap;
    }
}
.p-fluid {
    .p-button {
        width: auto;
    }
}

p#round {
    border-radius: 12px;
    border: 1px solid black;
    width: 52px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.highlight:hover {
    border-bottom: 3px solid Blue;
}
</style>